<template>
  <Navigation>
    <Loader v-if="loading" />
    <div class="about-us">
      <v-row justify="start" class="mt-5">
        <v-col cols="auto" offset="1">
          <h1>{{ $t("application.pages.about.header") }}</h1></v-col
        >
      </v-row>
      <v-row justify="center">
        <v-col cols="10" class="d-flex flex-column justify-center">
          <span v-html="$t('application.pages.about.welcome.text')"></span>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="auto">
          <router-link to="/menu" class="a-link">
            <h1 class="h1-button">
              {{ $t("application.pages.about.welcome.button") }}
            </h1></router-link
          >
        </v-col>
      </v-row>
      <v-row justify="space-around">
        <v-col cols="12"> <hr /></v-col>
      </v-row>
      <v-row justify="center" justify-md="start" align="center">
        <v-col cols="12" md="4">
          <v-img
            class="img-border"
            eager
            max-height="400"
            src="/assets/about/konstantina.jpg"
          >
          </v-img>
        </v-col>
        <v-col cols="12" md="4">
          <div
            class="pa-2 white-no-impo rounded"
            :class="{ 'text-card left': $vuetify.breakpoint.mdAndUp }"
          >
            <span>{{ $t("application.pages.about.welcome.left") }}</span>
          </div>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="4"><hr /></v-col>
      </v-row>
      <v-row justify="center" align="center" justify-md="end">
        <v-col cols="12" md="4" order-md="1" order="2">
          <div
            class="pa-2 white-no-impo rounded"
            :class="{ 'text-card right': $vuetify.breakpoint.mdAndUp }"
          >
            <span>{{ $t("application.pages.about.welcome.right") }}</span>
          </div>
        </v-col>
        <v-col cols="12" md="4" order-md="2" order="1">
          <v-img
            class="img-border"
            eager
            height="400"
            src="/assets/carousel/carousel_1.jpg"
          >
          </v-img>
        </v-col>
      </v-row>
      <v-row class="levina" align="baseline">
        <v-col cols="3"><v-img src="/assets/images/glastra.png"></v-img></v-col>
        <v-col cols="6"><v-img src="/assets/images/levina.png"></v-img></v-col>
        <v-col cols="3"><v-img src="/assets/images/glastra.png"></v-img></v-col>
      </v-row>
      <iframe
        title="Google maps"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3271.048659387989!2d24.921263315238413!3d34.930316580376164!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2256fae95bfddad3!2sMeltemi+Restaurant!5e0!3m2!1sen!2sde!4v1557773971636!5m2!1sen!2sde"
        width="100%"
        height="450"
        frameborder="0"
        style="border: 0"
        allowfullscreen=""
        data-rocket-lazyload="fitvidscompatible"
        data-lazy-src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3271.048659387989!2d24.921263315238413!3d34.930316580376164!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2256fae95bfddad3!2sMeltemi+Restaurant!5e0!3m2!1sen!2sde!4v1557773971636!5m2!1sen!2sde"
        class="lazyloaded"
        data-was-processed="true"
      ></iframe>
    </div>
  </Navigation>
</template>

<script>
import Navigation from "../components/navigation/Navigation.vue";
import Loader from "../components/loader/Loader.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Navigation,
    Loader,
  },
  mounted() {
    this.$store.commit("carousel/setTitle", "application.pages.about.title");
    this.$store.commit(
      "carousel/setSubtitle",
      "application.pages.about.subtitle"
    );
    this.$store.commit("carousel/setImage", "/assets/images/restaurant.jpg");
    this.$store.commit(
      "carousel/setHeaderImage",
      "/assets/images/IMG_5626-Pano-Edit.jpg"
    );
    this.$store.commit(
      "carousel/setHeaderTitle",
      "application.pages.about.header"
    );
  },
  computed: {
    ...mapGetters("business", ["images"]),
    ...mapGetters("loader", ["loading"]),
  },
};
</script>

<style lang="scss" scoped>
.about-us {
  font-family: "Roboto", sans-serif;
  padding: 0 1rem;
  .a-link {
    border: 1px solid;
    height: 6rem;
    line-height: 6rem;
    background-color: #fafafa70;
    border-radius: 6px;
    display: block;
    transition: border-color, background-color, 1s;

    h1 {
      transition: color, 1s;
      padding: 0 2rem;
    }
    &:hover {
      background-color: white;
    }
    &:hover,
    &:hover h1 {
      color: var(--v-primary-base) !important;
    }
    .social-icon {
      &:hover {
        color: var(--v-primary-base) !important;
      }
    }
  }
  .white-no-impo {
    background-color: var(--v-primary-darken1);
    color: white;
  }
  .left {
    left: -5rem;
  }
  .right {
  }
  .text-card {
    position: relative;
    width: 130%;
    z-index: 2;

    span {
      display: block;
      padding: 2rem 1rem;
      color: white;
      font-size: 1.5rem;
    }
  }
}
h1 {
  font-size: 2rem;
  font-family: "Dancing Script";
  color: gray;
}
.menu-btn {
  font-weight: 600;
}
.levina {
  filter: grayscale(1);
}

.img-border {
  border: 5px solid var(--v-primary-darken1);
}
</style>
